<template>
  <div class="role-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="never">
          <el-form :inline="true" :model="searchFrom" class="demo-form-inline searchFrom" size="small">
            <el-form-item :label="$t('searchForm.roleName')">
              <el-input
                v-model="searchFrom.roleName"
                :clearable="true"
                :placeholder="$t('searchForm.roleNamePlaceholder')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchForm.status')">
              <el-select v-model="searchFrom.roleStatus">
                <el-option :label="$t('searchForm.all')" value="" />
                <el-option :label="$t('enum.status[0]')" value="0" />
                <el-option :label="$t('enum.status[1]')" value="1" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="search">{{ $t('btn.search') }}</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <div class="table-block">
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ background: '#f7f9fc', border: 'none', color: '#252526' }"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column type="index" :label="$t('table.order')" width="100" align="center" />
        <el-table-column prop="roleName" :label="$t('table.roleName')" align="center" />
        <el-table-column prop="roleStatus" :label="$t('table.status')" align="center" width="100px">
          <template slot-scope="scope">
            <div :class="[scope.row.roleStatus == 1 ? 'status-point-normal' : 'status-point-freeze']">
              {{ $t(`enum.status[${scope.row.roleStatus}]`) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="roleRemark" :label="$t('table.remark')" align="center" />
        <el-table-column :label="$t('table.operate')" align="center">
          <template slot-scope="scope">
            <el-link :underline="false" type="primary" @click="check(scope.$index)">{{ $t('btn.check') }}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @changePage="changePage"
        :total="total"
        :page.sync="searchFrom.pageNo"
        :size.sync="searchFrom.pageSize"
      />
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="800px"
      :show-close="false"
      class="page-dialog"
      :close-on-click-modal="false"
      @close="onClose"
    >
      <template slot="title">
        <h3 class="dialog-title">{{ $t('btn.check') }}</h3>
      </template>
      <el-form
        ref="form"
        :model="form"
        :label-width="$i18n.locale === 'en' ? '200px' : '160px'"
        class="page-dialog-form"
      >
        <el-form-item :label="$t('dialog.roleManagement.roleName')">
          <el-input v-model="form.roleName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('dialog.roleManagement.status')">
          <el-select v-model="form.roleStatus" :disabled="true">
            <el-option :label="$t('enum.status[0]')" value="0" />
            <el-option :label="$t('enum.status[1]')" value="1" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('dialog.roleManagement.BuiltInRole')">
          <el-select v-model="form.builtIn" :disabled="true">
            <el-option :label="$t('enum.whether[0]')" value="0" />
            <el-option :label="$t('enum.whether[1]')" value="1" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('dialog.roleManagement.superAdminRole')">
          <el-select v-model="form.superAdmin" :disabled="true">
            <el-option :label="$t('enum.whether[0]')" value="0" />
            <el-option :label="$t('enum.whether[1]')" value="1" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('dialog.roleManagement.remark')">
          <el-input
            v-model="form.roleRemark"
            type="textarea"
            :disabled="true"
            :rows="form.roleRemark ? '' : 4"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">{{ $t('btn.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { roleList } from 'src/api/index'
import pagination from 'src/components/pagination'
export default {
  name: 'role-management',
  components: { pagination },
  data() {
    return {
      searchFrom: {
        roleName: '',
        roleStatus: '',
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
      tableData: [],
      total: 0,
      dialogVisible: false,
      form: {
        roleName: '',
        roleStatus: '',
        roleRemark: '',
        builtIn: '',
        superAdmin: '',
      },
    }
  },
  watch: {},
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      roleList(this.searchFrom).then(res => {
        this.loading = false
        this.tableData = res.data
        this.total = res.total
      })
    },

    changePage(pageParams) {
      const { pageNo, pageSize } = pageParams
      this.searchFrom = { ...this.searchFrom, pageNo, pageSize }
      this.getList()
    },

    search() {
      this.searchFrom = { ...this.searchFrom, pageNo: 1 }
      this.getList()
    },

    check(index) {
      const { roleName, roleRemark, roleStatus, builtIn, superAdmin } = this.tableData[index]
      this.form = { roleName, roleRemark, roleStatus, builtIn, superAdmin }
      this.dialogVisible = true
    },
    onClose() {
      this.$refs.form.resetFields()
    },
  },
}
</script>
<style lang="scss" scoped>
.role-management-page {
  background: transparent;
}
</style>
